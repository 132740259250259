<template>
  <div class="flex items-center flex-col pt-13 pb-7 relative">
    <div>
      <img src="@/assets/images/social/error.svg" alt="" />
    </div>
    <p
      class="px-32 mt-9 text-sm text-center"
      v-if="route.query.error_message"
      v-html="errorMessage"
    ></p>
    <p class="w-auth-form mt-9 text-sm text-center" v-else>
      Something went wrong
    </p>
    <ui-button
      @click="routeToForm()"
      rounded
      :button-type="ButtonType.Blue"
      class="mt-12 w-auth-form"
      >Try Again</ui-button
    >
    <p class="mt-16 text-xs font-medium">
      No account?
      <span class="text-red font-bold cursor-pointer" @click="openContact"
        >Contact us</span
      >
    </p>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { ROUTER_NAMES } from "@/router/router.enum";
import UiButton from "@/components/ui/UiButton.vue";
import { ButtonType } from "@/enums/button";
import { computed } from "vue";

export default {
  components: {
    UiButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const token = localStorage.getItem("token-for-password-creation");

    function routeToForm() {
      if (!token)
        router.push({
          name: ROUTER_NAMES.SIGN_IN,
        });
      else
        router.push({
          name: ROUTER_NAMES.CREATE_PASSWORD,
          query: { token: token },
        });
    }

    const errorMessage = computed(() => {
      const map = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#039;",
      };
      const escapeHtmlText = route.query.error_message.replace(
        /[&<>"']/g,
        function (m) {
          return map[m];
        }
      );
      return escapeHtmlText
        .replace("[strong]", "<strong>")
        .replace("[/strong]", "</strong>");
    });

    const openContact = () => {
      window.open(`${process.env.VUE_APP_LANDING}?show-contact=1`, "_blank");
    };

    return {
      router,
      route,
      ROUTER_NAMES,
      routeToForm,
      ButtonType,
      errorMessage,
      openContact,
    };
  },
};
</script>
